import { Dispatch, SetStateAction } from 'react';
import { Box, Typography } from '@mui/material';
import { Icon24UtilityChevronSmallRight, Icon24UtilityChevronRight } from '@care/react-icons';

import { VHPDataPublisher, VHPDataRetriever } from '@care/mfe-data-transfer-vhp';
import { SxClassProps } from '@/types';
import VERTICALS_INFO from '@/components/shared/VerticalsInfoValueDriverLayout';
import useResponsive from '@/components/hooks/useResponsive';
import {
  VerticalSelection as VerticalSelectionType,
  VERTICALS_NAMES,
  VERTICALS,
  CLIENT_FEATURE_FLAGS,
} from '@/constants';
import useTestFlags from '@/components/hooks/useTestFlags';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, ScreenName } from '@/types/enums';

const getClasses = (isFlagValue4: boolean, isAdultCareTileTest: boolean): SxClassProps => ({
  wrapper: {
    paddingBottom: {
      xs: 7,
      md: 10,
    },
    paddingX: {
      xs: 3,
      lg: 10,
    },
  },
  verticalsContainer: isAdultCareTileTest
    ? {
        justifySelf: 'center',
        justifyItems: 'center',
        display: 'grid',
        gridColumn: '2fr',
        gridTemplateColumns: {
          xs: 'repeat(3, 1fr)',
          sm: 'repeat(6, 2fr)',
          md: 'repeat(6, 2fr)',
          lg: 'repeat(6, 1fr)',
        },
        columnGap: {
          xs: 1,
          sm: 2,
        },
        '@media (min-width: 800px)': {
          columnGap: 2,
        },
        '@media (min-width: 1440px)': {
          columnGap: 3,
        },
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '0 auto',
      },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    ...(isAdultCareTileTest && {
      gridColumn: {
        sm: 'span 2',
        lg: 'span 1',
      },
      ':nth-last-of-type(2):nth-of-type(3n + 1)': {
        gridColumnEnd: { sm: 4, md: 'auto' },
      },
      ':last-child:nth-of-type(4n - 3)': {
        gridColumnEnd: { md: 6 },
      },
    }),
    display: 'flex',
    flexDirection: { xs: 'column' },
    alignItems: 'center',
    justifyContent: 'center',
    width: isAdultCareTileTest ? '104px' : 'calc(32% - 5px)',
    height: 'auto',
    borderRadius: 4,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    paddingY: 2,
    marginRight: isAdultCareTileTest
      ? 0
      : {
          xs: 1,
          sm: 4,
          md: 1,
          lg: 4,
        },
    marginBottom: {
      xs: 2,
      sm: 1.5,
    },
    '&:hover': {
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
    },
    '&:last-child': {
      marginRight: isAdultCareTileTest ? undefined : 0,
    },
    '@media (min-width: 600px)': {
      width: isAdultCareTileTest ? '170px' : '150px',
    },
    '@media (min-width: 700px)': {
      width: '180px',
    },
    '@media (min-width: 806px)': {
      width: '221px',
    },
    '@media (min-width: 960px)': {
      width: isAdultCareTileTest ? '192px' : '195px',
    },
    '@media (min-width: 1440px)': {
      marginRight: isAdultCareTileTest ? undefined : '22px',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 1,
  },
  verticalHeadline: {
    textAlign: 'center',
    fontSize: {
      sm: 'h1.fontSize',
      md: isFlagValue4 ? 'h1.fontSize' : 'display3.fontSize',
    },
    lineHeight: {
      sm: '34px',
      md: isFlagValue4 ? '34px' : '42px',
    },
    paddingBottom: { xs: isFlagValue4 ? 0 : 1, sm: 1 },
  },
  verticalSubHeadline: {
    textAlign: 'center',
    fontSize: {
      sm: 'h3.fontSize',
      md: 'h2.fontSize',
    },
    lineHeight: {
      sm: '24px',
      md: '26px',
    },
  },
});

type VerticalSelectionProps = {
  setSelectedVertical: Dispatch<SetStateAction<VerticalSelectionType>>;
  setOpenModalOrDrawer: Dispatch<SetStateAction<boolean>>;
};

function VerticalSelection({ setSelectedVertical, setOpenModalOrDrawer }: VerticalSelectionProps) {
  const { isMobile } = useResponsive();
  const { vhpMilestone1, adultCareTileTest } = useTestFlags();
  const VHPPublisher = new VHPDataPublisher();
  const VHPRetriever = new VHPDataRetriever();
  const existingData = VHPRetriever.retrieveData();

  const showSubHeader = [2, 3].includes(vhpMilestone1?.value);
  const isAdultCareTileTest = [2, 3].includes(adultCareTileTest?.value);
  const isFlagValue4 = vhpMilestone1?.value === 4;
  const classes = getClasses(isFlagValue4, isAdultCareTileTest);

  const headline = isFlagValue4
    ? 'Find background-checked care to help make life work'
    : "Join 29 million families who've turned to Care";

  const verticalsInfo = VERTICALS_INFO({
    adultCareTileTest: adultCareTileTest?.value,
    isDaycareRemoved: true,
  });

  const handleVerticalSelection = (vertical: VerticalSelectionType) => {
    if (isAdultCareTileTest) {
      // only update the shared flag data if the user has selected adult care
      const adultCareTileFlagValue =
        vertical === VERTICALS.ADULT_CARE ? adultCareTileTest?.value : null;
      const updatedSharedData = {
        ...existingData,
        featureFlagSharedData: {
          ...existingData?.featureFlagSharedData,
          [CLIENT_FEATURE_FLAGS.GROWTH_VHP_ADULT_CARE_TILE]: adultCareTileFlagValue,
        },
      };
      VHPPublisher.publishData(updatedSharedData);
    }

    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        cta_location: CtaLocation.VHP_BODY,
        vertical: VERTICALS_NAMES[vertical],
        cta_text: VERTICALS_NAMES[vertical],
      },
    });
    setSelectedVertical(vertical);
    setOpenModalOrDrawer(true);
  };

  return (
    <Box sx={classes.wrapper}>
      <Box pt={{ xs: 1, sm: 4, md: 6 }} pb={{ xs: 4 }} px={{ xs: 1, sm: 3 }}>
        <Typography variant="h2" sx={classes.verticalHeadline}>
          {headline}
        </Typography>
        {showSubHeader && (
          <Typography variant="body2" sx={classes.verticalSubHeadline}>
            Background-checked care for all you love.
          </Typography>
        )}
      </Box>
      <Box sx={classes.verticalsContainer}>
        {verticalsInfo.map((option, index) => (
          <Box
            key={option.vertical}
            tabIndex={index}
            role="button"
            sx={classes.item}
            data-testid={option.vertical}
            onClick={() => handleVerticalSelection(option.vertical)}
            onKeyDown={() => handleVerticalSelection(option.vertical)}>
            {option.valueDriversIcon}
            <Box sx={classes.label}>
              <Typography
                variant="h5"
                textAlign="center"
                fontSize={isMobile ? '12px' : '16px'}
                pr={{ xs: 0, sm: 1 }}>
                {option.displayName}
              </Typography>
              {isMobile ? (
                <Icon24UtilityChevronSmallRight size={16} />
              ) : (
                <Icon24UtilityChevronRight />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default VerticalSelection;
